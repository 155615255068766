var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Banner")]),
          _c("gov-body", [
            _vm._v("Review the banner content for the frontend.")
          ]),
          _c("ck-radio-input", {
            attrs: {
              value: _vm.frontend.banner.enabled,
              id: "cms.frontend.banner.enabled",
              label: "Enable/Disable Banner",
              hint: "Banner configuration will be shown when enabled",
              options: [
                { value: false, label: "Disabled" },
                { value: true, label: "Enabled" }
              ],
              error: null
            },
            on: {
              input: function($event) {
                _vm.frontend.banner.enabled = $event
              }
            }
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.frontend.banner.enabled,
                  expression: "frontend.banner.enabled"
                }
              ]
            },
            [
              _c("ck-banner-input", {
                attrs: {
                  errors: _vm.errors.get("cms.frontend.banner"),
                  showImageInput: true
                },
                model: {
                  value: _vm.frontend.banner,
                  callback: function($$v) {
                    _vm.$set(_vm.frontend, "banner", $$v)
                  },
                  expression: "frontend.banner"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }